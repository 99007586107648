import React from 'react'
import { Link } from "gatsby"
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import PageTransition from 'gatsby-plugin-page-transitions';

import { responsiveTitle1 } from '../components/typography.module.css'

const ThanksPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout>
      <PageTransition>
      <SEO title='Thank You' />
      <Container>
            <h1 data-sal="slide-up" data-sal-delay="10" data-sal-duration="600" data-sal-easing="ease" className={responsiveTitle1}>Thank You</h1>
            <p>Your message has been received. We will get back in touch with you as soon as possible.</p>
            <Link className="btn-link" to="/">Return Home</Link>
      </Container>
      </PageTransition>
    </Layout>
  )
}

export default ThanksPage
